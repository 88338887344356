<template>
  <NuxtLink v-if="tag === 'NuxtLink'" class="ui-button" :class="{ 'disabled': isButtonDisabled }" data-testid="element_000053">
    <slot />
  </NuxtLink>
  <component v-else :is="tag" class="ui-button" :class="{ 'disabled': isButtonDisabled }" data-testid="element_000053">
    <slot />
  </component>
</template>

<script lang="ts" setup>
interface Props {
  tag?: string
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'button',
})

const { tag, disabled } = toRefs(props)

const isButtonDisabled = computed(() => {
  return!!disabled.value
})
</script>

<style lang="scss" scoped>
@mixin standart-btn() {
  background: map_get($grayPalette, "color_28");
  transition: all .2s ease-in-out;

  &:not([disabled]):hover {
    background: map_get($grayPalette, "color_36");
  }

  &:not([disabled]):focus {
    background: map_get($grayPalette, "color_36");
    box-shadow: inset 0rem 0rem 0rem 2rem map_get($grayPalette, "color_58");
  }

  &:not([disabled]):active {
    background: map_get($grayPalette, "color_28");
    color: map_get($grayPalette, "color_9b");
    box-shadow: none;
  }

  &:not([disabled]).selected {
    background: map_get($grayPalette, "color_ff");
    color: map_get($grayPalette, "color_12");
    box-shadow: none;
  }
}

@mixin mini-btn() {
  @include standart-btn();
  text-transform: none;
  padding: 9rem 12rem;
}

.ui-button {
  padding: 9rem 24rem;
  font-size: 14rem;
  font-weight: 600;
  line-height: 22rem;
  letter-spacing: 0;
  border-radius: 10rem;
  text-transform: uppercase;
  color: map_get($grayPalette, "color_ff");
  cursor: pointer;
  text-align: center;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.disabled,
  &:disabled,
  &[disabled] {
    background: map_get($grayPalette, "color_62") !important;
    color: map_get($grayPalette, "color_9b") !important;
    cursor: not-allowed;
    pointer-events: none;

    use {
      stroke: map_get($grayPalette, "color_9b");
    }
  }

  &.only_blue {
    color: map-get($colorPalette, 'color_09') !important;
  }
}

.button {
  font-size: 14rem;

  &_primary {
    background-image: map_get($gradientsPalette, "variant_0");
    background-size: 1000% 100%;
    transition: all .2s ease-in-out;

    &.--active,
    &:hover {
      background-image: map_get($gradientsPalette, "variant_5");
      background-position: 0 0;
      background-size: 100% 100%;
    }

    &:focus {
      background-image: map_get($colorPalette, "color_09");
      box-shadow: inset 0 0 0 2rem map_get($colorPalette, "color_006");
    }

    &:active {
      background-image: map_get($gradientsPalette, "variant_3");
      box-shadow: none;
      background-size: 100% 100%;
    }
  }

  &_square {
    padding: 11rem;
    width: 40rem;
    height: 40rem;
  }

  &_most {
    background-image: map_get($gradientsPalette, "variant_6") !important;
    color: map_get($grayPalette, "color_ff") !important;
    box-shadow: none !important;
  }

  &_mini {
    padding: 6rem 8rem;
  }

  &_mini2 {
    padding: 9rem 12rem;
  }

  &_big {
    padding: 9rem 24rem;
  }

  &_txt-trm-none {
    text-transform: none;
  }

  &_full-width {
    width: 100%;
  }

  &_second {
    @include standart-btn();
  }

  &_chip {
    @include mini-btn();
    color: map_get($grayPalette, "color_9b");
  }

  &_action {
    @include mini-btn();

    &.active {
      color: map_get($grayPalette, "color_ff") !important;
      background-image: map_get($gradientsPalette, "variant_0") !important;
      background-size: 1000% 100%;

      &:active {
        box-shadow: none;
        background-image: map_get($gradientsPalette, "variant_3") !important;
      }

      &:focus {
        box-shadow: none;
      }

      &:hover {
        background-position: 0 0;
        background-size: 100% 100%;
      }
    }
  }

  &_base-text {
    text-transform: none;
  }
}
</style>
